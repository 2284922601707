body{margin:0;font-family:'Open Sans', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}

body{font-family:'Open Sans', sans-serif}hr{border-top:1px solid #ccc;margin-top:0}.text-bold{font-weight:700}h1,h2,h3,h4,h5,h6{font-weight:700}.navbar-default .navbar-nav>li>a{font-weight:400;font-style:normal;color:#fff}.btn-default{color:#fff;background-color:#0a6abc;border-color:#0C5CA4}.btn-default:hover,.btn-default:active,.btn-default:focus,.btn-default:active:focus{color:#fff;background-color:#0a6abc;border-color:#0C5CA4}.btn-primary{color:#000;background-color:#ffb612;border-color:#F9C856}.btn-primary:hover,.btn-primary:active,.btn-primary:focus,.btn-primary:active:focus{color:#000;background-color:#F9C856;border-color:#ffb612}.page-item.active .page-link{color:#fff;background-color:#0a6abc;border-color:#0a6abc}.page-link{color:#0a6abc}.navbar-default .navbar-nav>li>a:hover,.navbar-default .navbar-nav>li>a:focus{color:#fff;background-color:transparent}.navbar-default .navbar-nav>.active>a,.navbar-default .navbar-nav>.active>a:hover,.navbar-default .navbar-nav>.active>a:focus{color:#fff;background-color:#0a6abc}.panel{padding:1rem;background:#fff;border:1px solid #ccc;box-shadow:5px 5px 3px -3px rgba(0,0,0,0.3);font-size:.8rem}.navbar-dark .navbar-text,.navbar-dark .navbar-nav .nav-link{color:#fff}.table{background-color:#fff;box-shadow:5px 5px 3px -3px rgba(0,0,0,0.3)}.table tr.active-user{border:2px solid #ffb612}.thead-inverse th{color:#fff;background-color:#343a40}.next-deadline{font-size:1rem}.results-options{list-style:none}.results-options li{float:left}

.navbar{margin-top:30px;padding:.2rem 1rem}.navbar .left-nav{margin-left:8rem;margin-top:-.2rem;margin-bottom:-.2rem}@media (max-width: 991px){.navbar .left-nav{margin-top:1.5rem;margin-left:0}}.navbar .left-nav .nav-link:active,.navbar .left-nav .nav-link:hover{color:#fff}@media (min-width: 992px){.navbar .left-nav .nav-item:active,.navbar .left-nav .nav-item:hover{background:#0a6abc}}.navbar .navbar-nav>li{font-size:.85rem}.navbar .profile{font-size:1.3em}.navbar .navbar-logo{position:absolute;top:-30px;z-index:999}.navbar .navbar-text{padding:0}.navbar .navbar-text svg{vertical-align:text-bottom}

.m-playoff-seeds table{font-size:.75rem;box-shadow:none}.m-playoff-seeds table .thead-inverse th{color:#000;background-color:#eee}

.playoff-bracket .headers{display:flex;flex-direction:row;justify-content:space-between;align-items:center}.playoff-bracket .headers h6{width:150px;min-width:150px;text-align:center;margin:0 6px}.playoff-bracket .bracket{display:flex;flex-direction:row;justify-content:space-between}.playoff-bracket .bracket .round{display:flex;flex-direction:column;justify-content:center}

.playoff-matchup{position:relative;display:flex;flex-direction:column;justify-content:space-between;background:#fff;font-weight:100;font-size:.625rem;margin:12px 6px}.playoff-matchup .team{display:flex;justify-content:row;align-items:center;border:1px solid #343a40;border-radius:3px;margin-bottom:6px;font-weight:bold;width:150px}.playoff-matchup .team .seed{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:6px 12px;width:30px;height:100%;font-size:16px;background-color:#ffb612}.playoff-matchup .team .name{font-weight:normal;font-size:14px}.playoff-matchup .team .detail{flex:1 1;padding:6px;overflow:hidden}.playoff-matchup .team .player{overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.playoff-matchup .team .score{display:flex;flex-direction:column;justify-content:center;align-items:center;padding:6px 12px;height:100%;color:#fff;background-color:#343a40;font-size:16px;width:30px}.playoff-matchup.nfc .team .seed{background-color:#2e2e85;color:#fff}.playoff-matchup.afc .team .seed{background-color:#c71010;color:#fff}.playoff-matchup.superbowl .team .seed{background-color:#d3d3d3;color:#000}

.m-playoffs{min-height:270px}.m-playoffs .btn-group>.btn{flex-grow:1;background:#fff;color:#0a6abc}.m-playoffs .btn-group>.btn.active{background:#0a6abc;color:#fff}.m-playoffs .playoff-bracket-container{overflow-x:scroll}

.m-player-snapshot .player-stats dt{float:left}.m-player-snapshot .player-stats dd{text-align:right}.m-player-snapshot svg{vertical-align:middle}

.m-payouts .btn{cursor:pointer}.m-payouts .btn-group{width:100%}.m-payouts .btn-group>.btn{flex-grow:1;background:#fff;color:#0a6abc}.m-payouts .btn-group>.btn.active{background:#0a6abc;color:#fff}.m-payouts table{font-size:.75rem;box-shadow:none}.m-payouts table .thead-inverse th{color:#000;background-color:#eee}

.m-wall-of-champions .btn{cursor:pointer}.m-wall-of-champions .btn-group{width:100%}.m-wall-of-champions .btn-group>.btn{flex-grow:1;background:#fff;color:#0a6abc}.m-wall-of-champions .btn-group>.btn.active{background:#0a6abc;color:#fff}.m-wall-of-champions table{font-size:.75rem;box-shadow:none}.m-wall-of-champions table .thead-inverse th{color:#000;background-color:#eee}

.week-snapshot .week{font-weight:100;font-size:8rem;line-height:1}.week-snapshot .stat-box dt{float:left}.week-snapshot .stat-box dd{text-align:right}

.m-week-preview .separator{font-size:3rem;display:flex;justify-content:center;align-items:center;height:100%}@media (max-width: 767px){.m-week-preview .separator{font-size:1.5rem}}.m-week-preview .game-team{font-size:1rem;font-weight:300}@media (max-width: 767px){.m-week-preview .game-team{font-size:.625rem}}.m-week-preview .game-record{font-weight:600;font-size:1.2rem}@media (max-width: 575px){.m-week-preview .game-record{font-size:1rem}}@media (max-width: 767px){.m-week-preview .game-helmet{width:75px;height:auto}}.m-week-preview .carousel-control-next,.m-week-preview .carousel-control-prev{color:#0a6abc;top:25%;bottom:auto;bottom:initial;opacity:1.0;justify-content:initial}.m-week-preview .carousel-control-next{width:auto;width:initial}.m-week-preview .game-result{font-size:2.0rem}.m-week-preview .no-games{height:200px}

.m-gameday .pagination{margin:0}.m-gameday .title{margin-top:0}.m-gameday .game-capsule{background:#fff;border:1px solid #ccc}.m-gameday .game-capsule h6{font-size:.7rem}.m-gameday .game-capsule img{width:45px}.m-gameday .game-capsule .body .game-team{font-size:.625rem;font-weight:300}.m-gameday .game-capsule .body .game-divider{line-height:50px;font-size:2.0rem}.m-gameday .game-capsule .body .game-record{font-weight:600}.m-gameday .game-capsule .body .game-result{font-size:2.0rem}.m-gameday .game-capsule .footer{text-align:center}.m-gameday .game-capsule .footer .game-stat-title{font-size:1.2rem}.m-gameday .game-capsule .footer .game-stat-bar{min-height:15px;border:1px solid #ccc}

.m-nfl-division{font-size:0.625rem}

.m-leaderboards .btn{cursor:pointer}.m-leaderboards .table{font-size:.625rem}.m-leaderboards .playoff-cutoff-line{border-top:2px solid red}.m-leaderboards .btn-group>.btn{background:#fff;color:#0a6abc}.m-leaderboards .btn-group>.btn.active{background:#0a6abc;color:#fff}.m-leaderboards .legend{font-size:.8rem}

.m-game-submit-form .matchup-container{border:1px solid #000;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.m-game-submit-form .confidences{float:left}.m-game-submit-form .confidence{color:#fff;height:66px;font-size:2rem;border-bottom:1px solid #000}.m-game-submit-form .confidence:last-child{border-bottom:none}@media (max-width: 991px){.m-game-submit-form .confidence{height:62px}}@media (max-width: 767px){.m-game-submit-form .confidence{height:67px;font-size:1.25rem;line-height:50px}}

.matchup{position:relative;background:#fff;display:flex;justify-content:space-between;font-weight:100;font-size:.625rem;border-bottom:1px solid #000}.matchup.dragged{visibility:hidden}.matchup .team{flex-basis:25%;width:100%}.matchup .team .pointer-none{pointer-events:none}@media (min-width: 992px){.matchup .team{flex-basis:30%}}.matchup .team .team-logo{vertical-align:top}.matchup .team .team-name{font-size:.8rem}@media (max-width: 991px){.matchup .team .team-name{font-size:.625rem}}@media (max-width: 767px){.matchup .team .team-name{line-height:50px;font-size:1.25rem}}.matchup .team:hover{cursor:pointer}.matchup .team.selected{color:#fff;background:#0a6abc}.matchup .game-info{color:#fff;width:100%;cursor:move;position:relative;flex-basis:50%}@media (min-width: 992px){.matchup .game-info{flex-basis:40%}}@media (max-width: 767px){.matchup .game-info{text-align:center;background:none !important}}.matchup .game-info .kickoff-short{color:#000}.matchup .game-info .handle{position:absolute;top:0;right:0;height:64px;color:#6d6d6d}.matchup .game-info .handle svg{position:relative;right:-30px;height:64px}@media (max-width: 991px){.matchup .game-info .handle{height:60px}.matchup .game-info .handle svg{height:60px}}.matchup.playoffCutoffMarker{border-bottom:1px solid red}.matchup:last-child{border-bottom:none}

@media (min-width: 768px){.tiebreaker{width:auto;width:initial;margin:0;vertical-align:top}.tiebreaker input{height:28px}}

.m-scenario-generator .team{font-size:0.625rem}.m-scenario-generator .btn-group>.btn{background:#fff;border-color:#000;color:#000;padding-left:0.35rem;padding-right:0.35rem}.m-scenario-generator .btn-group>.btn.winner{background:#ccffcc}.m-scenario-generator .btn-group>.btn:hover{cursor:pointer}.m-scenario-generator label{font-size:0.75rem}

.m-user-picks .table{font-size:.625rem}.m-user-picks .table th,.m-user-picks .table td{vertical-align:middle}.m-user-picks .table .winner{background-color:#ccffcc}.m-user-picks .table .loser{background-color:#ffcccc}.m-user-picks .table .bi-star-fill{color:gold;vertical-align:text-bottom}

.m-user-profile-form label{width:100%}.m-user-profile-form .sub-title{font-size:0.625rem}.m-user-profile-form .favorites{font-size:0.70rem}.m-user-profile-form .status{font-weight:bold}.m-user-profile-form .error{color:red}

.player-card .championship{background-color:#e7e8e8;border-radius:5px;color:black}.player-card .badge{position:relative;top:3px}

